html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}


.iphone-bg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: 435px;

  @media only screen and (max-width : 1340px)  {
    left: initial;
    right: 10px;
    transform: initial;
  }

  @media only screen and (max-width : 845px)  {
    left: 50%;
    right: initial;
    transform: translateX(-50%);
  }
}

html {
  background-color: #424242;
  font-family: 'Lato', sans-serif;
}
.iframe-wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  top: 49px;
  border-radius: 40px;
  height: 836px;
  width: 393px;
  overflow: hidden;

  @media only screen and (max-width : 1340px)  {
    left: initial;
    right: 30px;
    transform: initial;
  }

  @media only screen and (max-width : 845px)  {
    left: 50%;
    right: initial;
    transform: translateX(-50%);
  }
}
.iframe-meta {
  width: 100%;
  height: 100%;
  border: none;
}

.content-wrapper {
  padding: 35px 45px;
  position: relative;
}

.users-list {
  margin: 55px 0 20px;
  .user-wrapper {
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    .profile-pic {
      border-radius: 25px;
      width: 50px;
      height: 50px;
      filter: grayscale(100%);
    }

    .user-info {
      margin-left: 10px;
      color: #919191;
      font-size: 12px;

      .welcome-text {
        margin-bottom: 3px;
      }
      .username {
        color: #fff;
      }
    }
  }
}

h1 {
  font-size: 36px;
  color: #E13E86;
  font-weight: 700;
  line-height: 35px;
}

.yellow-text {
  color: #FACD43;
}

.white-text {
  color: #fff;
}

.description {
  margin-top: 30px;
  font-size: 13px;
  color: #919191;
  line-height: 16px;
}

.meta-info {
  width: 384px;

  @media only screen and (max-width : 845px)  {
    display: none;
  }
}

.footer-menu {
  margin-top: 170px;
  a {
    color: #919191;
    font-size: 12px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  display: flex;
  align-items: center;

  .meta-mini {
    width: 20px;
    margin: 0 10px;
  }
}
